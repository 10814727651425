<template>
	<edit></edit>
</template>

<script>
	import edit from './out_storage_edit.vue'
	
	export default {
		name: 'm_outstorage_add',
		
		components: {
			edit
		}
	}
</script>